<template lang="pug">
  div
    v-row(v-if="!show_report")
      v-col(cols="12") Отчёт отсутствует
    v-row(v-else)
      v-col(cols="12")
        v-data-table(:headers="headers" :items="report" hide-default-footer class="elevation-1")
          template(v-slot:item.date="{ item }")
            v-edit-dialog(v-if="!isCustomer && isEdit" :return-value="item.date" large @save="saveDate(item)" @open="openDate(item)") {{ item.date }}
              v-icon mdi-pencil
              template(v-slot:input)
                v-menu(v-model="menu" transition="scale-transition" offset-y max-width="290px" min-width="auto")
                  template(v-slot:activator="{ on, attrs }")
                    v-text-field(v-model="computedDateFormatted"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on")
                  v-date-picker(v-model="date" no-title @input="menu = false" :max="new Date().toISOString().substr(0, 10)" first-day-of-week="1")
            span(v-else) {{ item.date }}
          template(v-slot:item.action="{ item }")
            div(class="text-nowrap")
              Export(:id="item.id")
              Photo(v-if="!isCustomer" :item="item", type="0" @func="callFunc")
              v-btn(v-if="!isCustomer" rounded small @click="del")
                v-icon mdi-delete
      v-col(cols="12")
        v-simple-table(class="elevation-1")
          template(v-slot:default)
            thead
              tr
                th(class="text-center") Комментарий
            tbody
              tr
                td
                  v-edit-dialog(:return-value="comment" large @save="saveComment" @open="openComment") {{ comment }}
                    v-icon mdi-pencil
                    template(v-slot:input)
                      v-text-field(v-model="tmpcomment")
      Photos(:uuid="id", :photos="photos", :item="report[0]", :id="id", type="0" @func="callFunc" v-if="photos.length > 0")
      v-col(cols="12")
        v-data-table(id="photos-container" :headers="dataHeaders" :items="reportdata" :footer-props="footer_props" class="elevation-1")
          template(v-slot:item="{ item }")
            tr
              td(v-for="(header, i) in dataHeaders" :key="i")
                template(v-if="header.value == 'promo'")
                  v-icon(v-if="item[header.value]" color="#ff4081") mdi-bookmark
                template(v-else-if="header.value != 'action'") {{ header.presense && item[header.value] == null ? header.def : item[header.value] }}
                div(class="text-nowrap" v-else)
                  v-btn(rounded small class="mr-3" @click="edit(item)")
                    v-icon mdi-pencil
                  Photo(:item="item", type="1" @func="callFunc")
            tr(v-if="item.photos.length > 0")
              td(:colspan="dataHeaders.length")
                v-row
                  Photos(:uuid="id", :photos="item.photos", :item="item", :id="item.id", type="1" @func="callFunc")
        v-dialog(v-if="!isCustomer" v-model="dialog" max-width="400px")
          v-card
            v-card-title
              span(class="headline") {{ current.product }}
            v-card-text
              v-container
                v-row
                  v-col(cols="12" :sm="fields.length > 1 ? 6 : 12" v-for="field in fields" :key="field.uuid")
                    v-text-field(v-if="field.fieldType.name == 'INT'" type="number" :rules="rules.int" :label="field.name" v-model.number="current.fields[field.uuid]['value']")
                    v-text-field(v-else-if="field.fieldType.name == 'FLOAT'" type="number" :rules="rules.float" :label="field.name" v-model.number="current.fields[field.uuid]['value']")
                    v-text-field(v-else-if="field.fieldType.name == 'DATE'" :label="field.name" v-model="current.fields[field.uuid]['value']")
                    v-text-field(v-else-if="field.fieldType.name == 'STRING'" :label="field.name" v-model="current.fields[field.uuid]['value']")
                    v-text-field(v-else-if="field.fieldType.name == 'BOOLEAN'" :label="field.name" v-model="current.fields[field.uuid]['value']")
                    v-select(v-else-if="field.fieldType.name == 'SELECT'" item-text="value" item-value="value" :items="field.options" :label="field.name" v-model="current.fields[field.uuid]['value']")
            v-card-actions
              v-spacer
              v-btn(color="blue darken-1" text @click="close") Отменить
              v-btn(color="blue darken-1" text @click="save") Сохранить
        v-dialog(v-if="!isCustomer" v-model="dialogDelete" max-width="500px")
          v-card
            v-card-title(class="headline") Выдействительно хотите удалить этот отчёт?
            v-card-actions
              v-spacer
              v-btn(color="blue darken-1" text @click="no") Нет
              v-btn(color="blue darken-1" text @click="yes") Да
        v-snackbar(color="green" v-model="snackbar.open" :timeout="snackbar.timeout") {{ snackbar.text }}
</template>


<script>
import { reportService } from '@/_services'
import { mapMutations, mapGetters } from "vuex";
import Export from './Export'
import Photos from './Photos'
import Photo from './Photo'

export default {
  components: {
    Export,
    Photos,
    Photo
  },

  props: ['id', 'network'],
  
  data: () => ({
    show_report: false,
    snackbar: {
      text: '',
      open: false,
      timeout: 2000
    },
    headers: [
      { text: 'Время', value: 'date' },
      { text: 'Проект', value: 'project' },
      { text: 'Ретейлер', value: 'retailer' },
      { text: 'Адрес', value: 'address' },
      { text: 'Действия', value: 'action' }
    ],
    report: [],
    fields: [],
    headersdata: [],
    reportdata: [],
    comment: '',
    tmpcomment: '',
    dialog: false,
    dialogDelete: false,
    current: {
      fields: {}
    },
    rules: {
      int: [ val => /\d/.test(val) || 'Это не число', val => (val || '').length > 0 || 'Пустое поле' ],
      float: [ val => /[\d.]/.test(val) || 'Это не число', val => (val || '').length > 0 || 'Пустое поле' ]
    },
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    presenses: ['V','OOS','OUT','middle','low'],
    footer_props: {
        'items-per-page-options': [25, 50, 100]
    },
    isEdit: false
  }),

  mounted() {
    this.loadReport()
  },

  computed: {
    ...mapGetters("authentication", ["checkRole"]),
    isCustomer() {
      return this.checkRole(0)
    },

    isCoordinator() {
      return this.checkRole(2)
    },

    dataHeaders() {
      let list = this.headersdata
      return !this.isCustomer ? [{ text: 'Акция', value: 'promo', sortable: false }].concat(list, { text: 'Действия', value: 'action' }) : list
    },

    computedDateFormatted() {
      return this.dateFormat(new Date(Date.parse(this.date)))
    },

    photos() {
      return this.report != null && this.report.length > 0 ? this.report[0].photos : []
    }
  },

  watch: {
    project: function(val) {
        this.loadNetworks(val)
    }
  },

  methods: {
    ...mapMutations(["setAppBackLink"]),
    loadReport() {
        this.loadingData(reportService.get, this.id, data => {
          let date = new Date(Date.parse(data.date))
          this.comment = data.comment
          this.fields = [].concat(data.reportField)
          this.fields.push({
            uuid: '',
            name: 'Наличие',
            options: ['V','OOS','OUT','middle','low', ''],
            fieldType: {
              name: 'SELECT',
              value: 5
            }
          })
          this.fields.push({
            uuid: '-1',
            name: 'Комментарий',
            fieldType: {
              name: 'STRING',
              value: 0
            }
          })
          let arr = this.fields.map(field => Object.assign({ reportFieldUuid: field.uuid, reportFieldDataUuid: '', value: '' }, field))
          for(let item of arr) {
            this.current.fields[item.uuid] = item
          }
          data.reportData.sort(this.filterByProductName('product'));
          this.report = []
          this.report.push(data)
          this.report[0].date = this.dateFormat(date)
          this.report[0]['photos'] = data.reportPhoto
          this.date = date.toISOString().substr(0, 10)
          this.headersdata = [
            { text: 'Продукт', value: 'product', sortable: false },
            { text: 'Наличие', value: 'presense', sortable: false },
            { text: 'Комментарий', value: 'comment', sortable: false }
          ]
          this.reportdata = []
          for(let i = 0, rfd = data.reportField; i < rfd.length;i++) {
            this.headersdata.push({
              text: rfd[i].name, value: rfd[i].uuid, sortable: false, presense: rfd[i].presense, def: rfd[i].defValue
            })
          }
          for(let i = 0, rd = data.reportData; i < rd.length;i++) {
            this.reportdata.push(this.parseData(rd[i]))
          }
          // this.reportdata.sort((a, b) => a.action >= b.action
          //   ? a.product > b.product ? 1 : a.product < b.product ? -1 : 0
          //   : -1);
          this.isEdit = data.edit;
          this.show_report = true;

        })
    },

    filterByProductName(field) {
      return (a, b) => a[field] > b[field] ? 1 : -1;
    },

    save() {
      reportService.editData(this.id, this.current.id, Object.values(this.current.fields)).then(d => {
        let idx = this.reportdata.findIndex((el, i, arr) => el.id == this.current.id)
        if (idx != -1) {
          this.$set(this.reportdata, idx, this.parseData(d))
        }
      })
      this.dialog = false
    },

    close() {
      this.dialog = false
    },

    edit(item) {
      this.current.id = item.id
      this.current.product = item.product
      for(let it in this.current.fields) {
        if (it == '') {
          this.current.fields[it].value = item.presense
        } else if (it == '-1') {
          this.current.fields[it].value = item.comment
        } else {
          let o = item.field.find(i => i.field.uuid == it)
          if (o != null) {
            this.current.fields[it].value = o.value
            this.current.fields[it].reportFieldDataUuid = o.id
          } else this.current.fields[it].value = 0
        }
      }
      this.dialog = true
    },

    yes() {
      this.loadingData(reportService.remove, this.id, data => {
        this.alert('Отчёт удалён');
        this.show_report = false;
        window.close();
      })
    },

    no() {
      this.dialogDelete = false
    },

    del() {
      this.dialogDelete = true
    },

    saveDate(d) {
      reportService.edit(this.id, { date: this.date }).then(data => {
        d.date = this.computedDateFormatted
        this.alert('Дата изменена');
      })
    },

    saveComment() {
      reportService.edit(this.id, { comment: this.tmpcomment }).then(data => {
        this.comment = this.tmpcomment;
        this.alert('Комментарий изменен');
      })
    },

    openComment() {
      this.tmpcomment = this.comment;
    },

    openDate(d) {
      let [day, month, year] = d.date.split('.')
      this.date = new Date(Date.parse(`${year}-${month}-${day}`)).toISOString().substr(0, 10) 
    },

    dateFormat(date) {
        return `${date.getDate() < 10? '0' : ''}${date.getDate()}.${date.getMonth() + 1 < 10? '0' : ''}${date.getMonth() + 1}.${date.getFullYear()}`
    },

    callFunc(func, item, type, param_1, photo, res) {
      func(this.id, photo, param_1).then(res).catch(error => {
        console.log(error)
      }).finally(() => {
        if (type == 0) this.report = this.report.slice(0);
        else this.reportdata = this.reportdata.slice(0);
      })
    },

    parseData(data) {
      let obj = { promo: data.action, id: data.id, product: data.product, presense: data.presense, field: data.reportFieldData, photos: data.reportPhoto, comment: data.comment }
      for(let j = 0, rfd = data.reportFieldData; j < rfd.length;j++) {
        obj[rfd[j].field.uuid] = rfd[j].value
      }
      return obj
    },
    alert(text) {
      this.snackbar.text = text;
      this.snackbar.open = true
    }
  }
}
</script>

<style>
    .text-nowrap {
      white-space: nowrap !important;
    }
    .v-btn .v-input__prepend-outer {
      margin-top: 4px;
      margin-right: 0;
    }
</style>
